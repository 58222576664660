<template>
  <div class="content">
    <div class="top">
      <h1>信件列表</h1>
    </div>
    <div class="main">
      <div class="mainContent">
        <el-card style="margin-bottom: 20px;">
          <!--    搜索与添加区域-->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-autocomplete
                class="inline-input"
                v-model="selectMessageType"
                :fetch-suggestions="getAllMessageType"
                placeholder="查找">
                <el-button @click="messageTypeCallback" slot="append" icon="el-icon-search"
                  >搜索</el-button
                >
              </el-autocomplete>
            </el-col>
            <el-col :span="10">
              <el-date-picker
                v-model="startTimestamp"
                type="daterange"
                align="right"
                @change="startTimeCallback"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="tableBox">
          <el-table :data="tableData" style="width: 100%;">
            <!-- <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-timeline prop="studentGroupList">
                    <el-timeline-item
                      :timestamp="props.row.replyTime"
                      placement="top"
                      color="#409EFF">
                      <el-card>
                        <p>{{ props.row.replyName }}：{{ props.row.replyContent }}</p>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </el-form>
              </template>
            </el-table-column> -->
            <el-table-column prop="creatTime" label="提交时间"></el-table-column>
            <el-table-column prop="messageTitle" label="标题"></el-table-column>
            <el-table-column prop="messageType" label="类型">
              <template slot-scope="scope">
                <el-tag disable-transitions>{{ scope.row.messageType }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="replyTime" label="回复时间"></el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination background layout="prev, pager, next" :total="tableData.length">
            </el-pagination>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'EmailList',
  data() {
    return {
      queryInfo: {
        pageSize: 10,
        pageNum: 1
      },
      selectMessageType: '',
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      startTimestamp: ''
    };
  },
  methods: {
    async getAllMessages() {
      let res = await axios.get('/mailbox/Visitor/Reception/findAllMessages', {
        params: {
          page: this.queryInfo.pageNum,
          size: this.queryInfo.pageSize
        }
      });
      console.log(res);
      if (res.data.code === 200) {
        this.tableData = res.data.object;
      }
    },
    async getAllMessageType(_, callback) {
      try {
        let data = await axios({
          url: '/mailbox/Visitor/Reception/findAllTypes',
          method: 'GET'
        });
        callback(
          data.data.object.map((item) => {
            return {value: item.typeName};
          })
        );
      } catch (err) {
        console.log(err);
      }
    },
    async getMessagesByOpts({type, startTime, endTime}) {
      console.log('1111');
      const params = {
        page: this.queryInfo.pageNum,
        size: this.queryInfo.pageSize
      };
      if (type) {
        params.type = type;
      }
      if (startTime) {
        params.startTime = startTime;
      }
      if (endTime) {
        params.endTime = endTime;
      }
      try {
        let data = await axios.get('/mailbox/Visitor/Reception/findSuchMessages', {
          params
        });
        console.log(data);
        if (data.data.code === 200) {
          this.tableData = data.data.object;
        } else if (data.data.message === '没有数据') {
          this.tableData = [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    messageTypeCallback() {
      this.getMessagesByOpts({type: this.selectMessageType});
    },
    getData(n) {
      n = new Date(n);
      return n.toLocaleDateString().replace(/\//g, '-') + ' ' + n.toTimeString().substr(0, 8);
    },
    async startTimeCallback() {
      const startTime = this.getData(this.startTimestamp[0].getTime());
      const endTime = this.getData(this.startTimestamp[1].getTime());
      await this.getMessagesByOpts({type: this.selectMessageType, startTime, endTime});
    }
  },
  mounted() {
    this.getAllMessages();
  },
  watch: {
    'startTimestamp': {
      handler: function () {
        this.startTimeCallback();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.el-timeline {
  padding-left: 2rem;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 100px;
    width: 100%;
    // background-image: url(../assets/schoolPic.png);
    // background-repeat: no-repeat;
    // background-size: 50%;
    // background-position: 100%;
    background-image: linear-gradient(to right bottom, white, #e0efff);
    h1 {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 100%;
      font-family: emoji;
    }
  }
  .main {
    flex: 1;
    background-color: #409eff;
    padding: 1px 0;
    height: 100%;
    .mainContent {
      padding: 10px 0;
      height: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      .tableBox {
        flex: 1;
      }
    }
  }
}
</style>
